@import "../../scss/variables";

.PropertyList{
  height: 100%;
  width: 100%;
  overflow: scroll;
  &--almagro{
    background-color: #202020;
    color: #ffffff;
  }
  &--socovesa{
    background-color: #202020;
    color: #ffffff;
  }
  &--pilares{
    background-color: #202020;
    color: #ffffff;
  }
  &__header{
    display: flex;
    flex-direction: row;
    padding: 20px 20px 0;
    &__container{
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      &__projectName{

      }
      &__projectNumber{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: -0.02px;
        color: #bebfc5;
        &__detail{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          margin-right: 5px;
          border-radius: 20px;
          height: 20px;
          width: 20px;
          &--almagro{
            color: $white;
            background-color: $almagroPrimary;
          }
          &--socovesa{
            color: $white;
            background-color: $socovesaPrimary; 
          }
          &--pilares{
            color: $white;
            background-color: $pilaresPrimary;
          }
        }
      }
    }
  }
  &__container{
    padding: 40px 30px;
  }
}
