.CalendarItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 25px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 10px;
  justify-content: space-between;
  &--almagro {
    background-color: #141414;
  }
  &--socovesa {
    background-color: #4c5256;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__date {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: -0.02px;
      color: #ffffff;
      margin: 0;
    }
    &__title {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      text-transform: uppercase;
      line-height: 1.38;
      letter-spacing: -0.01px;
      margin-top: 6px;
    }
    &__colapse {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.01px;
      margin-top: 9px;
    }
  }
  &__button {
    color: inherit !important;
    // margin-left: auto !important;
  }
}
