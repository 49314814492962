.PropertyItem{
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 0 10px;
  &--almagro{
    background-color: #000000;
    color: #ffffff;
  }
  &--pilares{
    background-color: #000000;
    color: #ffffff;
  }
  &--socovesa{
    background-color: #000000;
    color: #ffffff;
  }
  &__propertyNumber{
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__label{
      font-size: 0.8rem;
    }
    &__number{
      margin-left: 20px;
    }
    &__button{
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .PropertyItem{
    flex-direction: column;
  }
}
