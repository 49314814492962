@import "../../scss/variables";

.Home {
  &__body {
    background-color: inherit;
    &--pilares {
      background-color: $pilaresSecondary;
    }
    &--socovesa {
      background-color: inherit;
    }
    &--almagro {
      background-color: inherit;
    }
    &__greeting {
      font-size: 18px;
      letter-spacing: -0.02px;
      &--socovesa {
        color: $lightGrey;
      }
      &--almagro {
        color: $lightGrey;
      }
      &--pilares {
        color: $lightGrey;
      }
    }
    &__username {
      margin: 0 0 20px!important;
      &--socovesa {
        color: $socovesaPrimary;
      }
      &--almagro {
        color: $white;
      }
      &--pilares {
        color: $pilaresPrimary;
      }
    }
    &__section {
      display: flex;
      &__container {
        display: flex;
        flex-direction: column;
        margin: 50px 0 36px;
        overflow: initial;
        &:only-child {
          flex-basis: 100%;
        }
        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ * {
          flex-direction: column;
          flex-basis: 50%;
          margin: 0 30px 0px 0px;
          &:last-child {
            margin: 0;
          }
        }
        &__header {
          display: flex;
          flex-direction: row;
          align-items: center;
          > * {
            margin-left: 10px;
          }
          &__name {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.02px;
            &--socovesa {
              color: $lightBlack;
            }
            &--almagro {
              color: $white;
            }
          }
          &__detail {
            font-size: 12px;
            letter-spacing: -0.01px;
            color: #9f9f9f;
          }
          &__button {
            margin-left: auto !important;
          }
        }
        &__list {
          display: flex;
          flex-direction: column;
          &__item {
            &:nth-child(-n + 3) {
              display: flex;
              flex-direction: row;
              flex-basis: 33.3333%;
              margin: 0 30px 0 0;
              &:last-child {
                margin: 0;
              }
            }
            &:only-child {
              display: flex;
              flex-direction: row;
              flex-basis: 100%;
              margin: 0;
            }
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
            &--socovesa {
              background-color: $socovesaBackground;
            }
            &--almagro {
              background-color: $almagroSecondary;
            }
            &--pilares {
              background-color: $white;
            }
            &--pilares {
              background-color: #ffffff;
            }
          }
        }
      }
    }
    &__rectangle {
      margin: 30px 0 40px;
      width: 100%;
      height: 54px;
      border-radius: 4px;
      &--socovesa {
        background-color: $socovesaPrimary;
      }
      &--pilares {
        background-color: $pilaresPrimary;
      }
      &--almagro {
        background-color: $almagroSecondary;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Home {
    &__Menu {
      display: none;
    }
    &__body {
      padding: 100px 15px;
      &__greeting {
        font-size: 14px;
      }
      &__username {
        font-size: 28px;
      }
      &__messages {
        padding: 10px;
        &__notification {
          font-size: 14px;
        }
        &__url {
          padding: 0 !important;
        }
      }
      &__section {
        flex-direction: column;
        &__container {
          margin: 0 0 36px;
          overflow: hidden !important;
          &__header {
            &__button {
              display: none !important;
            }
          }
          &:first-child:nth-last-child(2),
          &:first-child:nth-last-child(2) ~ * {
            flex-direction: column;
            flex-basis: 100%;
            margin: 0;
            &:last-child {
              margin: 0;
            }
          }
          // Home__body__section__container__list__item
          &__list {
            flex-direction: column;
            &__item {
              &:nth-child(-n + 3) {
                flex-direction: column;
                flex-basis: auto;
              }
              width: 100%;
            }
          }
        }
      }
    }
  }
}
