.ProjectTable {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  &__mobile{
    display: none;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 40px;
  }
  &__subheader{
    // border-bottom: solid 1px #ededef;
  }
  &__body {
    &__table {
      width: 100%;
      table-layout: auto;
      white-space: nowrap;
      border-spacing: 15px;
      &__header {
        padding: 0px 0px 15px 0px;
        &__item {
          &:nth-child(-n+3) {
            padding: 0px 0px 15px 15px;
          }
          padding: 0px 0px 15px 30px;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: -0.01px;
          color: #a7a7a7;
          text-align: left;
          text-transform: uppercase;
          &__center {
            text-align: center;
          }
        }
      }
    }
     &__emptyMessage{
        padding: 30px;
        text-align: center;
        color: #696666;
        box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
     }
  }
  &__footer{
    padding: 23px 0px;
    margin-left: auto;
    &__addMoreLabel{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__icon{
        margin-left: 7px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProjectTable__desktop{
    display: none;
  }
  .ProjectTable__mobile{
    display: flex;
    flex-direction: column;;
  }
  &__footer{
    padding: 23px 0px;
    margin-left: auto;
    &__addMoreLabel{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__icon{
        
      }
    }
  }
}
