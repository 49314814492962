@import "../../scss/variables";

.NotificationList {
  padding: 10px 18px;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(103, 105, 119, 0.4);
  margin: 0 0 5px;
  &--socovesa {
    background-color: $white;
    border-left: 10px solid $socovesaPrimary;
  }
  &--almagro {
    color: $white;
    background-color: $almagroSecondary;
    border-left: 10px solid $almagroPrimary;
  }
  &--pilares {
    background-color: $white;
    border-left: 10px solid $pilaresPrimary;
  }
  &--empresas{
    background-color: $white;
    border-left: 10px solid #0093ca;
  }
  &__buttonWrapper{
    width: 100%!important;
  }
  &__container{
    display: flex;
    align-items: center !important;
    width: 100%;
    &__url {
      text-transform: none !important;
      text-decoration: underline !important;
    }
    &__iconWrapper {
      display: flex;
      align-items: center;
      border-radius: 50%;
      padding: 5px;
      margin-right: 10px;
      &__icon {
      }
      &--almagro {
        background-color: $almagroPrimary;
      }
      &--socovesa {
        background-color: $lightBlack;
      }
      &--pilares {
        background-color: $lightBlack;
      }
      &--empresas{
        background-color: #303446;
      }
    }
    &__notification {
      color: inherit;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-transform: none;
      font-size: 14px;
    }
    &__url {
      margin-left: auto !important;
    }
  }
}
