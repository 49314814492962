// Almgaro
$almagroPrimary: #ee3424;
$almagroBackground: #000000;
$almagroSecondary: #333333;

// Pilares
$pilaresPrimary: #ff6900;
$pilaresBackground: #33383c;
$pilaresSecondary: #EDEDEF;

// Socovesa
$socovesaPrimary: #53308f;
$socovesaBackground:#ffffff;
$socovesaSecondary: #EFF3F8;
$socovesaMenu: #415364;

// Empresas
$empresasPrimary: #003a63;
$empresasSecondary: #0093ca;

// General
$white:#ffffff;
$black: #000000;
$lightGrey: #acacac;
$lightBlack: #303446;
