.DocumentItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #b7aec8;
  margin: 0 0 10px;
  &__nameDateItem{
    display: grid;
      &__date{
        font-size: 12px;
        color:#8e8f96;
        margin-bottom: 6px;
        text-transform: none !important;
      }
      &__docname{
        font-size: 14px;
        font-weight: bolder;
      }
      &__lastModifiedDate{
        display: none;
      }
  } 
  &--socovesa{
    color: #303446;
    background-color: inherit;
  } 
  &--almagro{
    color: #ffffff;
    background-color: inherit;
  }
  &--pilares{
    color: #303446;
    background-color: inherit;
  }
  &--empresas{
    color: #303446;
    border: 1px solid #b7aec8;
    background-color: inherit;
  }
  &__simbol{
    padding: 15px;
    &--socovesa{
      color: inherit;
      background-color: inherit;
    } 
    &--almagro{
      color: inherit;
      background-color: inherit;
    }
    &--pilares{
      color: inherit;
      background-color: inherit;
    }
    &--empresas{
      color: inherit;
      background-color: inherit;
    }
    &__icon{
      color: inherit;
    }
  }
  &__container{
    display: flex;
    align-items: center;
    padding: 0 20px 0;
    flex-basis: 100%;
    &--socovesa{
      color: #303446;
    }
    &--almagro{
      color: #ffffff;
    }
    &--pilares{
      color: #303446;
    }
    &--empresas{
      color: #303446;
    }
    &__buttons{
      display: flex;
      flex-direction: row;
      margin-left: auto;
      align-items: center;
      &__lastModifiedDate{
        margin-right: 72px;
      }
    }
    &__name{
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: -0.02px;
      color: inherit;
      display: flex;
      text-decoration: none;
      &--pointer{
        cursor: pointer;
    }
    }
    &__date{
      display: flex;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.02px;
      color: #676977;
    }
    &__icon{
      display: flex;
      margin-left: auto;
      margin-right: 5px;
      &--socovesa{
        color: #53308f;
      }
      &--almagro{
        color: #ffffff;
      }
      &--pilares{
        color: #ff6900;
      }
      &--empresas{
        color: #6b6b6b;
      }
    }
  }
}

.DeleteButton{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__container{
    display: flex;
    border: 1px solid #acacac;
    color: #acacac;
    border-radius: 50%;
  }
  &__label{
    display: flex;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.01px;
    color: #acacac;
  }
}

@media only screen and (max-width: 768px) {
  .DocumentItem{
    &__nameDateItem{
      &__date{
        display: none;
      }
      &__lastModifiedDate{
        display: flex;
      }
    }
    &__container{
      &__buttons{
        &__lastModifiedDate{
          display: none;
          margin: 0;
        }
      }
    }
  }
}
