.dialog-view {
  border: #8e8f96 1px solid;
}

.dialog-new {
  border: #8e8f96 1px solid;
}

.ProjectList{
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  &__container{
    display: grid;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
  }
  &__expansionPanelDetails{
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px;
  }
}

.ProjectDetailPV {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  padding: 0px 30px;
  &__expansionPanelSummary{
    display: inline-grid;
    &__estado-solicitud {
      display: inline;
    }
    &__solicitud-data {
      display: flex;
      flex-direction: column;
      &__one {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
    }
      &__barra-tareas {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 0.75rem;
        .warning {
          color: red;
        }
      }
      &__addressName{
        font-size: 12px;
        color:#8e8f96;
        margin-bottom: 6px;
        font-weight: bold;
        margin-left: 34px;
        text-transform: none !important;
      }
      &__UnitName{
        font-size: 14px;
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        span {
          font-size: 12px;
          color: #8e8f96;
          font-weight: normal;
          margin-left: 5px;
        }
        &__ProyD {
          display: inline-block;
        }
        &__ProyM {
          display: none;
        }
        &__Nro {
          display: inline-block;
        }
      }
      &__Icono{
        margin-right: 10px;
      }
  }
  &__expansionPanelDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 32px 0px;
    &__botones {
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between;
    }
    &__table{
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 2fr 2fr 2fr 5fr 2fr;
      width: 100%;
      border-style: none;
      &__row{
        display: table-row;
        border: #b7aec8 1px solid;
        border-collapse: collapse;
        border-style: inset;
        border-spacing: 0px;
        width: 100%;
        &__header{
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          font-weight: bolder;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          font-size: 12px;
        }
        &__cell{
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          vertical-align: middle;
          font-size: 12px;
          color:#8e8f96;
          word-break: break-word;
          white-space: pre-line;
          &__icon{
            cursor: pointer;
          }
        }
      }
    }
    &__desktop {
      display: flex;
    }
    &__mobile {
      display: none;
      flex-direction: column;
      &__requirement{
        border: 1px solid;
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
      }
      &__details{
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 5px;
        &__icon {
          cursor: pointer;
        }
        > span {
          display: flex;
          align-items: center;
        }
      }
    }
    &__documents {
      position: absolute;
      top: 10px;
      right: 56px;
    }
    &__document{
      &__content{
        cursor: pointer;
        height: 100%;
         a {
          // color: #8e8f96;
          text-decoration: none;
          font-size: 12px;
          font-weight: bold;
        }
         a:hover {
          text-shadow: 0 0 1px #8e8f96;
        }
      }
    }
  }
  &__check{
    position: absolute;
    right: 60px;
    top: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    > span {
      padding-right: 15px;
      display: inline-block;
    }
  }
  &__newRequest {
    padding: 2rem;
    &__buttons {
      display: flex;
      gap: 0.5rem;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
      button {
        color: #ffffff;
        &.danger {
          background-color: #c70000;
        }
        &.primary{
          background-color: rgb(48, 133, 214);
        }
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.UnitDetail {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 0 10px;
  &--almagro{
    background-color: #000000;
    color: #ffffff;
  }
  &--pilares{
    background-color: #000000;
    color: #ffffff;
  }
  &--socovesa{
    background-color: #000000;
    color: #ffffff;
  }
  &__property{
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__label{
      font-size: 0.8rem;
    }
    &__value{
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProjectTable__desktop{
    display: none;
  }
  .ProjectTable__mobile{
    display: flex;
    flex-direction: column;;
  }
  &__footer{
    padding: 23px 0px;
    margin-left: auto;
    &__addMoreLabel{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .ProjectList{
    &__container{
      padding: 10px 10px;
      margin-top: 0px;
    }
  }
  .ProjectDetailPV {
    padding: 0px;
    &__check {
      > span {
        display: none;
      }
    }
    &__expansionPanelSummary{
      &__UnitName{
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        &__Nro {
          display: none;
        }
        &__ProyD {
          display: none;
        }
        &__ProyM {
          display: inline-block;
        }
      }
      &__barra-tareas {
        flex-direction: column-reverse;
      }
    }
    &__expansionPanelDetails{
      &__mobile{
        display: flex;
      }
      &__desktop {
        display: none;
      }
    }
  }
}

.send-request-title {
  font-weight: bold;
}