.ProjectTableItem {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--invalid-name);
  font-family: 'Source Sans Pro';
  white-space: nowrap;
  &__responsive{
    display: none;
  }
  &__column {
    padding: 24px 30px;
    margin: 0 0 10px;
    text-align: left;
    max-width: 20%;
    &__center{
      text-align: center;
    }
    &__circle {
      font-size: 12px;
      background-color: #0093ca;
      color: #ffffff;
      padding: 4px 9px;
      border-radius: 50%;
    }
  }
  &__progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    & > * {
      margin-right: 30px;
    }
    &__button {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProjectTableItem {
    display: none;
    &__responsive{
      display: flex;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
      background-color: #ffffff;
      margin: 0 0 20px;
      &__container{
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &__itemContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:not(:last-child){
          border-bottom: solid 1px #ededef;
        }
      }
      &__item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0px 20px 10px;
        &--fullSize{
          align-items: flex-start;
        }
        &__button{
          position: initial;
        }
        &__label{
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.01px;
          color: #bebfc5;
        }
        &__value{
        }
      }
    }
  }
}
