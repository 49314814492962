.Solicitud {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  &__fecha_contenedor {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  &__fecha {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      border: 1px solid #fff;
      padding: 0.5em;
      border-radius: 1%;
    }
    // margin-top: 1em;
  }
  // &__garantias {
  //   display: flex;
  //   justify-content: center;
  //   // padding: 0.7em 0;
  //   gap: 1em;
  //   > div {
  //     > div {
  //       display: flex;
  //       width: 100%;
  //       > span {
  //         width: fit-content;
  //         text-align: center;
  //         justify-self: flex-end;
  //       }
  //       > strong {
  //         padding-right: 0.5em;
  //       }
  //     }
  //   }
  // }
  &__garantias {
    display: flex;
    width: 92%;
    gap: 1em;
    justify-content: center;
    border-top: 1px solid;
    margin: 1rem 2rem;
    border-bottom: 1px solid;
    padding: 0.5rem 0rem;
    > span {
      width: 100%;
      text-align: center;
      padding-bottom: .3em;
    }
  }
  &__buttons{
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin-top: 1em;
  }
  &__titulo {
    display: flex;
    width: 90%;
    justify-content: center;
    position: absolute;
    top: 0.7em;
    margin-left:1rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid;
    > span {
      width: 100%;
      text-align: center;
      padding-bottom: .3em;
    }
  }
  &__requerimiento {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1em;
    &__imagen {
      margin: 1em;
      background-color: black;
      border: 1px solid white;
      border-radius: 1%;
      width: 368px;
      height: 275px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__img {
        width: 368px;
        height: 275px;
      }
      > label {
        padding: 0;
        line-height: 0;
      }
      &__button{
        &__hidden {
          display: none;
        }
      }
      > picture {
        background-color: white;
      }
    }
    &__formulario {
      display: flex;
      flex-direction: column;
      gap: .25em;
      &__input {
        // text-transform: uppercase;
        margin-bottom: 1em;
      }
    }
  }
}

.MuiFormControl-root {
  margin: 0 5em;
  width: 25em;
}

.MuiInput-root {
  > svg {
    color: rgba(255, 255, 255, 0.54);
  }
}

@media only screen and (max-width: 768px) {
  .Solicitud {
    font-size: 0.8em;
    &__garantias {
      flex-direction: column;
      gap: 1em;
      > div {
        width: 100%;
        > strong {
          width: 45%;
        }
      }
    }
    &__titulo {
      width: 80%;
    }
    &__fecha {
      width: 100%;
    }
    &__requerimiento {
      grid-template-columns: 1fr;
      &__formulario {
        &__input {
          font-size: 0.8em;
          > label {
            padding-top: 0.7em;
            font-size: 1.5em;
          }
        }
      }
      &__imagen {
        position: inherit;
        display: flex;
        width: 260px;
        height: 200px;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 1em;
        > label {
          position: inherit;
          line-height: 0;
        }
        &__img {
          width: 260px;
          height: 200px;
        }
        > img {
          width: 260px;
          height: 200px;
        }
      }
    }
  }
  .MuiInputBase-root {
    font-size: 1em;
  }
  .MuiSelect-select.MuiSelect-select {
    font-size: .7em;
  }
  .MuiInputLabel-formControl {
    font-size: 1.2em;
  }
}
