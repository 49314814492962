@import '../../scss/variables';

.CalendarCard {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  &__calendar{
    width: 100%;
    .rdrMonthsVertical {
      flex-direction: column;
      align-items: center;
    }
    &--pilares{
      .rdrDayToday .rdrDayNumber span:after{
        background-color: $pilaresPrimary;
      }
    }
    &--socovesa{
      .rdrDayToday .rdrDayNumber span:after{
        background-color: $socovesaPrimary;
      }
    }
    &--almagro{
      .rdrDayToday .rdrDayNumber span:after{
        background-color: $almagroPrimary;
      }
      color: #ffffff;
      .rdrMonthAndYearWrapper{
        color: #ffffff;
        background-color: #333333;
        .rdrPprevButton{
          background-color: #333333;
          i{
            border-color: transparent #ffffff transparent transparent;
          }
        }
        .rdrNextButton{
          background-color: #333333;
          i{
            border-color: transparent transparent transparent #ffffff;
          }
        }
      }
      .rdrMonths{
        color: #ffffff;
        background-color: #333333;
        .rdrDays{
          .rdrDay{
            .rdrDayNumber span{
              color: #ffffff!important;
            }
          }
        }
      }
    }
  }
  &__calendarFooter{
    display: flex;
  }
  &__calendarButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px;
    &--almagro {
      color: $white;
    }
    &--socovesa {
      color: $black;
    }
    &--pilares {
      color: $black;
    }
    &__icon {
      padding: 0;
      height: auto;
      width: auto;
      border-radius: 50%;
      margin: 0 5px;
      &--almagro {
        color: $white;
        background-color: $almagroPrimary !important;
      }
      &--pilares {
        color: $white;
        background-color: $pilaresPrimary !important;
      }
      &--socovesa {
        color: $white;
        background-color: $socovesaPrimary !important;
      }
    }
  }
  &__alertIcon {
    display: flex;
    padding: 25px 10px 0px 25px;
    &--almagro {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: $almagroPrimary !important;
      }
    &--pilares {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: $pilaresPrimary !important;
      }
    &--socovesa {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: $socovesaPrimary !important;
      }
  }

  &--almagro {
    &--active {
      background-color: #202020;
      color: #ffffff;
    }
  }
  &--socovesa {
    &--active {
      background-color: #33383c;
      color: #ffffff;
    }
  }
  &--pilares {
    &--active {
      background-color: #33383c;
      color: #ffffff;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 38px 35px 15px;
    &--almagro {
      color: #ffffff;
    }
    &--active {
      padding: 25px;
      justify-content: space-between;
      border-bottom: 1px solid #bebfc5;
    }
    &__arrow {
      display: flex;
      align-items: center;
      color: inherit !important;
      text-transform: inherit !important;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.02px;
      text-align: right;
    }
    &__label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-align: center;
      color: #bebfc5;
      margin-right: 10px;
    }
    &__date {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.02px;
      color: inherit;
    }
  }
  &__detailButton {
    display: flex !important;
    align-items: center !important;
    margin-left: auto !important;
    padding: 20px !important;
    color: inherit !important;
    &__icon {
      margin-left: 10px;
    }
  }
  &__body {
    &--active {
      padding: 16px 30px;
    }
    &__list {
      margin: 15px 0 0;
    }
    &__backArrow {
      color: inherit !important;
    }
    &__errorMessage {
      align-items: center;
      text-align: center;
      flex-direction: row;
      padding: 15px 25px;
      border-radius: 3px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      margin: 20px 0 10px;
      background-color: #4c5256;
    }
  }
}
