@import '../../scss/variables';

.Navbar{
  &__desktop{
    display: flex;
    padding: 30px;
    flex-basis: 100%;
    background-color: inherit;
    &--socovesa{
      border-bottom: 1px solid #d1d1d1;
    }
    &--almagro{
      border-bottom: 1px solid #d1d1d1;
    }
    &--pilares{
      border-bottom: 1px solid #d1d1d1;
    }
    &--empresas{
      border-bottom: 1px solid #d1d1d1;
    }
    &__date{
      display: inherit;
      justify-items: flex-start;
      font-style: italic;
      letter-spacing: -0.02px;
      &--socovesa{
        color: #303446;
      }
      &--almagro{
        color: #8e8e8e;
      }
      &--empresas {
        color: #003a63;
      }
    }
    &__actions{
      display: flex;
      justify-items: flex-end;
      align-items: center;
      margin-left: auto;
      &--almagro{
        color:#ffffff;
      }
      &--socovesa{
        color: #303446;
      }
      > * {
        margin-right: 30px!important;
      }
      &__avatarLabel{
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.02px;
        color: inherit;
      }
      &__avatar{
        height: 30px;
        width: 30px;
        margin: 0 5px;
        color: inherit;
        border-radius: 50%;
      }
      &__notificationButton{
        position: relative!important;
        &--hasNotifications{
          &--almagro{
            &:before{
              content: '';
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $almagroPrimary;
              top: -2px;
              z-index: 100;
              right: -5px;
            }
          }
          &--socovesa{
            &:before{
              content: '';
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $socovesaPrimary;
              top: -2px;
              z-index: 100;
              right: -5px;
            }
          }
          &--pilares{
            &:before{
              content: '';
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $pilaresPrimary;
              top: -2px;
              z-index: 100;
              right: -5px;
            }
          }
          &--empresas{
            &:before{
              content: '';
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $empresasSecondary;
              top: -2px;
              z-index: 100;
              right: -5px;
            }
          }
        }
      }
      &__notifications{
        height: 30px;
        width: 30px;
        position: relative;
        &:before{
          content: '';
          display: inline-block;
          border-radius: 50%;
          position: absolute;
          width: 5px;
          height: 5px;
          background-color: red;
        }
        &--almagro{
          color: #ffffff!important;
          &:hover{
            g{
              fill: $almagroPrimary!important;
            }
          }
        }
        &--socovesa{
          color: #303446!important;
          &:hover{
            g{
              fill: $socovesaPrimary!important;
            }
          }
        }
        &--pilares{
          color: #6b6b6b!important;
          &:hover{
            g{
              fill: $pilaresPrimary!important;
            }
          }
        }
        &--empresas{
          color: #6b6b6b!important;
          &:hover{
            g{
              fill: $empresasSecondary!important;
            }
          }
        }
      }
      &__logoutButton{
        &--almagro{
          color: #ffffff!important;
          &:hover{
            g{
              fill: $almagroPrimary!important;
            }
          }
        }
        &--socovesa{
          color: #303446!important;
          &:hover{
            g{
              fill: $socovesaPrimary!important;
            }
          }
        }
        &--pilares{
          color: #6b6b6b!important;
          &:hover{
            g{
              fill: $pilaresPrimary!important;
            }
          }
        }
        &--empresas{
          color: #6b6b6b!important;
          &:hover{
            g{
              fill: $empresasSecondary!important;
            }
          }
        }

      }
    }
  }
  &__mobile{
    display: none;
    flex-basis: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 0 20px;
    position: fixed;
    padding: 0px 20px 20px 0px;
    border: solid 1px #d1d1d1;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    &__icongroup {
      display: flex;
      align-items: flex-end;
      gap: 16px;
    }
    &--socovesa{
      background-color: #ffffff;
      .bm-menu {
        background-color: #ffffff;
      }
    }
    &--almagro{
      background-color: #000000;
      .bm-menu {
        background-color: #333333;
      }
    }
    &--pilares{
      background-color: #EDEDEF;
      .bm-menu {
        background-color: #33383c;
      }
    }
    &--empresas{
      background-color: #ffffff;
      .bm-menu {
        background-color: #ffffff;
      }
    }
    &__logo{
      display: flex;
      align-self: center;
      margin: 20px 60px 0px;
    }
    &__notifications{
      margin: 25px 0 0;
      margin-left: auto;
      &--almagro{
        color: #ffffff!important;
        &:hover{
          g{
            fill: $almagroPrimary!important;
          }
        }
      }
      &--socovesa{
        color: #303446!important;
        &:hover{
          g{
            fill: $socovesaPrimary!important;
          }
        }
      }
      &--pilares{
        color: #6b6b6b!important;
        &:hover{
          g{
            fill: $pilaresPrimary!important;
          }
        }
      }
      &--empresas{
        color: #6b6b6b!important;
        &:hover{
          g{
            fill: $empresasSecondary!important;
          }
        }
      }
    }
    &__innerMenu{
      &__header{
        padding: 13px 18px;
        &--almagro{
          color: #ffffff;
          border-bottom: 10px solid #3e3e3e;
        }
        &--socovesa{
          color: #303446;
          border-bottom: 10px solid #ededef;
        }
        &__logo{
          display: block;
          margin: 0 auto;
        }
        &__portraid{
          display: flex;
          align-items: center;
          font-size: 14px;
          margin: 30px 0 30px;
          &__avatar{
            height: 45px;
            width: 45px;
            border-radius: 50%;
          }
          &__name{
            color: inherit;
            margin: 0 10px 0;
          }
          &__drowDownButton{
            margin-left: auto;
            margin-right: 15px;
          }
        }
      }
      &__Menu{
        padding: 40px 15px 0px;
        font-size: 14px;
      }
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 20px;
      height: 20px;
      left: 20px;
      top: 25px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #a90000;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
      left: 8px;
      right: auto;
      position: absolute!important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
      font-size: 1.15em;
      > * {
        outline: none;
      }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      // padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

@media only screen and (max-width: 959px) {
  .Navbar{
    &__desktop{
      display: none;
    }
    &__mobile{
      display: flex;
    }
  }
}
